<template>
  <LibraryView :publications="publications" :custom-counters="customCounters" />
</template>

<script>
import LoggerFactory from '@/services/utils/LoggerFactory';
const logger = LoggerFactory.getLogger('library.vue');

import ManagePublicationsStates from '@/enums/ManagePublicationsStatesEnum';
import AppStateEnum from '@/enums/AppStateEnum';

import AppConstantsUtil from '@/services/utils/AppConstantsUtil';
import SearchPublicationsFactory from '@/classes/factories/SearchPublicationsFactory';

import pageCommonMixin from '@/components/mixins/pageCommonMixin';
import LibraryView from '@/components/views/LibraryView/LibraryView.vue';

import publicationUtils from '@/services/utils/publicationUtils';

function getCurrentLanguage(route, store) {
  const lang = route?.params?.pathMatch?.replace(/\/$/, '');
  const languagesList = store.getters['ContextStore/getLibraryLanguages'];

  if (lang && languagesList.includes(lang)) {
    store.commit('ContextStore/setCurrentLanguage', lang);
  }
  return store.getters['ContextStore/currentLanguageGetter'];
}

export default {
  name: 'LibraryPage',
  components: {
    LibraryView
  },
  mixins: [pageCommonMixin],
  async asyncData({ route, store }) {
    if (!store.getters['UserStore/getRunId']) {
      return;
    }
    const currentLanguage = getCurrentLanguage(route, store);
    logger.info(`Get library language: ${currentLanguage}`);
    const builder = SearchPublicationsFactory.getSearcherBuilder();
    builder
      .setState(ManagePublicationsStates.LIBRARY)
      .setLanguage(currentLanguage)
      .setFilter('');

    const currentSearcher = store.getters['LibraryStore/getLibrarySearcher'];
    const newSearcher = builder.build();
    const searcher =
      currentSearcher && currentSearcher.lang === newSearcher.lang
        ? currentSearcher
        : newSearcher;
    if (process.server) {
      store.dispatch('LibraryStore/destroyStates', [
        ManagePublicationsStates.LIBRARY
      ]);
    }
    let pageName = AppStateEnum.MANAGE_PUBLICATION;
    if (currentLanguage !== 'en') {
      pageName = AppStateEnum.MANAGE_PUBLICATION_LANGUAGE.replace(
        'language',
        currentLanguage
      );
    }
    await store.dispatch('LibraryStore/applyLibraryFilter', searcher);
    let publications, customCounters;
    if (process.server) {
      const allPublications = store.getters['LibraryStore/getLibrary'];
      const groupedPublications = publicationUtils.groupPublicationsByCategory(
        allPublications,
        store
      );
      ({ publications, customCounters } = groupedPublications.reduce(
        (result, category) => {
          result.customCounters[
            category.categoryName
          ] = publicationUtils.countPublicationItems(category.publications);
          result.publications.push(...category.publications.slice(0, 12));
          return result;
        },
        { publications: [], customCounters: {} }
      ));
      store.commit('LibraryStore/destroyLibrary');
    }
    const canonicalLinkSetting = store.getters[
      'ContextStore/getPageCanonicalLinkSetting'
    ](pageName);
    return { canonicalLinkSetting, publications, customCounters };
  },
  data() {
    return {
      htmlClassList: this.$store.getters[
        'ContextStore/getPlatformHtmlClassList'
      ]
    };
  },
  head() {
    const headSettings = this.getCommonHeadSettings();
    const link = this.canonicalLinkSetting ? [this.canonicalLinkSetting] : [];
    return {
      ...headSettings,
      link,
      htmlAttrs: {
        ...headSettings.htmlAttrs,
        class: [
          ...this.htmlClassList,
          AppConstantsUtil.MANAGE_PUBLICATION_SCROLL_CLASS
        ]
      }
    };
  },
  computed: {
    isOnline() {
      return this.$store.getters['ContextStore/isOnline'];
    }
  },
  mounted() {
    this.$store.subscribe(mutation => {
      if (
        mutation.type === 'ContextStore/setServiceClientInit' &&
        this.isOnline
      ) {
        this.publications = [];
        this.customCounters = {};
      }
    });
  }
};
</script>
