<template>
  <div class="library-page">
    <div
      class="pa-4 pt-2 pa-md-6 pt-md-2 pa-lg-6 pa-xl-6 mx-auto library-page-content"
    >
      <LibraryBanner class="mb-6" />
      <div ref="libraryFilterAnchor" class="library-filter-anchor"></div>
      <LibraryFilter
        :searcher="getLibrarySearcher"
        show-categories
        show-extra
        show-language
        @change="filterChanged"
      />
      <LibraryViewByCategory
        v-if="hasPublications"
        :publications="libraryPublications"
        :custom-counters="customCounters"
      />
      <div v-else class="no-books-found">
        <div><img :src="noBooksImg" alt="no books" /></div>
        <h3>{{ $t('LibraryPage.placeholder.noBooks') }}</h3>
        <p
          class="description"
          v-html="$t('LibraryPage.placeholder.tryAdjusting')"
        />
      </div>
    </div>
    <AppFooter />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import images from '@/assets/images';
import libraryViewScrollMixin from '@/components/mixins/libraryViewScrollMixin';
import SessionStorageService from '@/services/SessionStorageService';

import LibraryViewByCategory from '@/components/views/LibraryView/LibraryViewByCategory/LibraryViewByCategory.vue';
import AppFooter from '@/components/views/AppFooter/AppFooter.vue';

export default {
  name: 'LibraryView',
  components: {
    LibraryBanner: () =>
      import('@/components/views/LibraryBanner/LibraryBanner.vue'),
    LibraryFilter: () =>
      import('@/components/views/LibraryFilter/LibraryFilter.vue'),
    LibraryViewByCategory,
    AppFooter
  },
  mixins: [libraryViewScrollMixin],
  props: {
    publications: {
      type: Array,
      default: () => []
    },
    customCounters: Object
  },
  data() {
    return { noBooksImg: images.picSearch };
  },
  computed: {
    ...mapGetters('LibraryStore', ['getLibrarySearcher', 'getFilteredLibrary']),
    hasPublications() {
      return this.libraryPublications.length;
    },
    libraryPublications() {
      return this.getFilteredLibrary.length
        ? this.getFilteredLibrary
        : this.publications;
    },
    isWide() {
      return this.$store.getters['MediaDetectorStore/mediaSize'].wide;
    }
  },
  destroyed() {
    SessionStorageService.set(
      'previousRoute',
      this.$store.getters['ContextStore/getPreviousNotBookRoute']
    );
  },
  methods: {
    filterChanged(searcher) {
      this.$store.dispatch('LibraryStore/applyLibraryFilter', searcher);
      this.$nextTick(() => {
        this.scrollToFilterAnchor();
      });
    },
    scrollToFilterAnchor() {
      const staticOffset = this.isWide ? 20 : 4;
      const filterElement = this.$refs.libraryFilterAnchor;
      window.scrollTo({
        top: filterElement.offsetTop - staticOffset
      });
    }
  }
};
</script>

<style lang="less" src="./LibraryView.less"></style>
