import debounce from 'lodash/debounce';
import AppConstantsUtil from '@/services/utils/AppConstantsUtil';

export default {
  data() {
    return {
      isEditor: this.$store.getters['ContextStore/isEditorApp'],
      debouncedOnScroll: null,
      mixinScrollSelector: `.${AppConstantsUtil.MANAGE_PUBLICATION_SCROLL_CLASS}`,
      observer: null,
      fullPath: this.$route.fullPath
    };
  },
  mounted() {
    if (this.isEditor) {
      return;
    }
    const position = this.$store.getters[
      'OpenManagePublicationsStore/getRecentScrollPosition'
    ](this.fullPath);
    this.debouncedOnScroll = debounce(this.saveScrollPosition, 500);
    window.addEventListener('scroll', this.debouncedOnScroll);
    this.$nextTick(() => {
      this.scrollToLibraryPositionOnMounted(this.mixinScrollSelector, position);
    });
  },
  destroyed() {
    window.removeEventListener('scroll', this.debouncedOnScroll);
    this.$_stopObserve();
  },
  methods: {
    getScrollElement() {
      return window.document.querySelector(this.mixinScrollSelector);
    },
    scrollToLibraryPositionOnMounted(scrollSelector, position) {
      if (this.isEditor) {
        return;
      }
      this.observer = new MutationObserver(mutations => {
        mutations.forEach(mutation => {
          if (mutation.attributeName === 'class') {
            const scrollAdded = mutation.target.classList.contains(
              AppConstantsUtil.MANAGE_PUBLICATION_SCROLL_CLASS
            );
            if (scrollAdded) {
              this.scrollToLibraryPosition(scrollSelector, position);
              this.$_stopObserve();
            }
          }
        });
      });
      const targetNode = document.querySelector('html');
      const options = {
        attributes: true
      };
      this.observer.observe(targetNode, options);
    },
    saveScrollPosition() {
      const scrollEl = this.getScrollElement();
      this.$store.commit(
        'OpenManagePublicationsStore/setRecentScrollPosition',
        { key: this.fullPath, position: scrollEl.scrollTop }
      );
    },
    $_stopObserve() {
      if (this.observer) {
        this.observer.disconnect();
      }
    },
    scrollToLibraryPosition(scrollSelector, position = 0) {
      const container = document.querySelector(scrollSelector);
      container.scrollTo(0, position);
    }
  }
};
