<template>
  <div class="library-list -no-padd">
    <div
      v-for="group in groupedPublications"
      :key="group.categoryName"
      dense
      class="category-item-block"
    >
      <Category
        :level-label="group.levelLabel"
        :category-name="group.categoryName"
        :publications="group.publications"
        :custom-counter="getCustomCounter(group.categoryName)"
        @libraryItemEvent="libraryItemEventHandler"
        @categoryEvent="categoryEventHandler"
      />
    </div>
  </div>
</template>

<script>
import Category from '@/components/views/LibraryView/Category.vue';

import publicationUtils from '@/services/utils/publicationUtils';

export default {
  name: 'LibraryViewByCategory',
  components: {
    Category
  },
  props: {
    publications: {
      type: Array,
      required: true
    },
    customCounters: Object
  },
  computed: {
    groupedPublications() {
      return publicationUtils.groupPublicationsByCategory(
        this.publications,
        this.$store
      );
    }
  },
  methods: {
    libraryItemEventHandler(payload) {
      this.$emit('libraryItemEvent', payload);
    },
    categoryEventHandler(payload) {
      this.$emit('categoryEvent', payload);
    },
    getCustomCounter(categoryName) {
      return this.customCounters?.[categoryName];
    }
  }
};
</script>

<style lang="less" src="./LibraryViewByCategory.less"></style>
